// import "../../style.scss";
import "./header.scss"
import { Link, useNavigate, useLocation} from "react-router-dom";
import React, { useState } from "react";
import Logger from "../../utils/logger";
import {usePsyfyClient} from "../../hooks/psyfyClient";

const Header: React.FC<HeaderProps> = ({ isChatbotPage = false }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
 


  const isLoggedIn = () => !!localStorage.getItem("userEmail");
  const {  requestSummarySurrogate} = usePsyfyClient();

  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const callSummaryBackend = async () => {
    // Log the action and call the backend for a summary
    Logger.debug("Calling Summary Backend...");
    try {
      await requestSummarySurrogate();
      Logger.debug("Summary requested successfully.");
    } catch (error) {
      Logger.error("Error requesting summary", error);
    }
  };
  

  //const handleLogout = () => {
  const handleLogout = async () => {

    const confirmed = window.confirm("Are you sure you want to log out?");
    if (!confirmed) return; 

    if (isChatbotPage) {
      await callSummaryBackend();
    }
    localStorage.clear();
    sessionStorage.clear();

    alert("You have now logged out of the system.");

    //Redirect after logout
    navigate("/");
  };
  const navbarStyle = {
    backgroundColor: isChatbotPage ? "#EF7B45" : "#fafafa",
  };

   // Check if current path is /chatbot
   const isChatbotRoute = location.pathname === '/chatbot';

  return (
    <nav className="navbar" style={navbarStyle}>
      <button
        className="burger-menu-toggle"
        onClick={() => setMenuOpen(!menuOpen)}
      >
        ☰
      </button>

      <div className={`menu ${menuOpen ? "open" : ""}`}>
      <ul className="navbar-nav">

      {/* <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto"> */}
          <li className="nav-item">
            <Link className="nav-link" to="/"  onClick={() => {
        scrollToTop();
        setMenuOpen(false);
      }}>
              Main
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/intro"  onClick={() => {
        scrollToTop();
        setMenuOpen(false);
      }}>
              Intro
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/research"  onClick={() => {
        scrollToTop();
        setMenuOpen(false);
      }}>
              About
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/support"  onClick={() => {
        scrollToTop();
        setMenuOpen(false);
      }}>
              Support
            </Link>
          </li>

          {/* profile only shows after login */}
          {isLoggedIn() && (
            <li className="nav-item">
              <Link className="nav-link" to="/profile"  onClick={() => {
        scrollToTop();
        setMenuOpen(false);
      }}>
                MyProfile
              </Link>
            </li>
          )}

    
          {!isLoggedIn() && (
            <li className="nav-item">
              <Link className="nav-link" to="/login" onClick={() => {
                scrollToTop();
                setMenuOpen(false);
              }}>
                Login
              </Link>
            </li>
            
          )}
        {/* {!isLoggedIn() && ( */}
        {/* <li className="nav-item">
                    <Link className="nav-link" to="/support"  onClick={() => {
        scrollToTop();
        setMenuOpen(false);
      }}>
                      Support
                    </Link>
                  </li> */}
                  {/* )} */}

          {isLoggedIn() && (
            <li className="nav-item logout-button">
              <button onClick={() => {
                handleLogout();
                scrollToTop();
                setMenuOpen(false);
              }} className="nav-link">
                Logout
              </button>
            </li>
          )}
          
      

         {isLoggedIn() && (
            <li className="nav-item">
              <Link className="nav-link" to="/credits" onClick={() => {
                scrollToTop();
                setMenuOpen(false);
              }}>
                Subscription
              </Link>
            </li>
          )}

          {!isChatbotRoute && (
            <li className="nav-item">
              <Link className="nav-link" to="/chatbot"  onClick={() => {
        scrollToTop();
        setMenuOpen(false);
      }}>
                Chat with Psyfy
              </Link>
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Header;
