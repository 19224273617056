import React, { useState, useEffect }  from 'react';
import { useNavigate } from 'react-router-dom';
import './profile_main.scss';
import avatar from '../../assets/Mia_logo1.jpg';
import { usePsyfyClient } from "../../hooks/psyfyClient";
import moodProfileimage from '../../assets/mood_profile.png';
import personProfileimage from '../../assets/person_profile.jpg';


const translations = {
  en: {
    yourCredits: "Your Credits:",
    manageSubscription: "Manage Subscription",
    deleteAccount: "Delete Account",
    deletingAccount: "Deleting Account...",
    moodProfileButton: "Mood Profile",
    moodProfile: "The Mood Profile provides a deep dive into your emotional well-being. Visualize your progress through intuitive graphs. You can unlock the Mood Profile after completing 20 exchanges with Psyfy. ",
    personalityProfileButton: "Personality Profile",
    personalityProfile: "The Personality Profile offers a comprehensive look at who you are. You can unlock the Mood Profile after completing 60 exchanges with Psyfy. ",
    changeVoice: "Change Voice",
    enhanceVoice: "Enhance your experience in voice mode: Choose between the Basic voice or the Advanced Realistic voice.",
    conversationHistory: "Conversation History",
    reviewConversations: "Review all your past conversations here.",
    conversationSummary: "Conversation Summary",
    highlightsTakeaways: "Your highlights and key takeaways at a glance.",
    chatnow: "Chat Now"
  },
  zh: {
    yourCredits: "您的积分：",
    manageSubscription: "管理订阅",
    deleteAccount: "删除账户",
    deletingAccount: "正在删除账户...",
    moodProfileButton: "情绪指数",
    moodProfile: "通过生动直观的图表，轻松追踪您的情绪变化，完成与 Psyfy 的 20 次互动对答后即可解锁“情绪档案”。",
    personalityProfileButton: "性格类型",
    personalityProfile: "现在你不用做性格测试就可以更了解自己的性格类型啦， 完成与 Psyfy 的 60 次互动对答后即可解锁“情绪档案”",
    changeVoice: "更改语音",
    enhanceVoice: "提升您的语音体验：选择基础语音或高级真实语音。",
    conversationHistory: "对话历史",
    reviewConversations: "在这里查看您所有的历史对话。",
    conversationSummary: "对话总结",
    highlightsTakeaways: "查看您每一轮的对话总结。",
    chatnow: "开始对话"
  }
};


const CreditsTab: React.FC<{ credits: number | null, language: 'en' | 'zh' }> = ({ credits, language }) => {
  const text = translations[language];
  return (
    <div className="credits-tab">
      <p className="font">{text.yourCredits} {credits !== null ? credits : 'Loading...'}</p>
    </div>
  );
};
   


const ProfilePage = () => {
  const navigate = useNavigate();
  const { fetchUserCredits, deleteAccount, fetchUserLanguageFromBackend } = usePsyfyClient();
  const userEmail = localStorage.getItem("userEmail");
  const [userCredits, setUserCredits] = useState<number | null>(null);
  const [deletingAccount, setDeletingAccount] = useState(false);
  const [language, setLanguage] = useState<'en' | 'zh'>('en'); 
  const text = translations[language as 'en' | 'zh']; 

  const handleConversationHistoryClick = () => {
    // Navigate to the Conversation History page
    navigate('/conversation');
  };

  const handleConversationSummaryClick = () => {
    // Navigate to the Summary page
    navigate('/summary');
  };

  const handleSubscription = () => {
    // Navigate to the Summary page
    navigate('/credits');
  };

  const handleChangeVoice = () => {
    // Navigate to the Summary page
    navigate('/voices');
  };

  const handleChatNowClick = () => {
    navigate('/chatbot'); // Navigate to the Chatbot page
  };

  useEffect(() => {
    const fetchAndSetLanguage = async () => {
      try {
        const userLanguage = await fetchUserLanguageFromBackend();
        if (userLanguage) {
          setLanguage(userLanguage === 'chinese' ? 'zh' : 'en');
          localStorage.setItem("language", userLanguage);
        }
      } catch (error) {
        console.error("Error fetching user language:", error);
      }
    };
  
    if (userEmail) {
      fetchAndSetLanguage();
    }
  }, [userEmail, fetchUserLanguageFromBackend]);
  

  useEffect(() => {
    if (!userEmail) {
      navigate("/login"); // Redirect to login if no userEmail found.
    }
  }, [navigate, userEmail]);


  useEffect(() => {
    const updateCredits = async () => {
      const credits = await fetchUserCredits();
      setUserCredits(credits);
    };

    if (userEmail) {
      updateCredits();
    }
  }, [userEmail, fetchUserCredits]);

  const handleMoodProfileClick = () => {
    navigate('/moodprofile'); // Navigate to the Mood Profile page
  };

  const handlePersonProfileClick = () => {
    navigate('/personprofile'); // Navigate to the Mood Profile page
  };

  const handleDeleteAccount = async () => {
    if (!userEmail) {
      alert("User email not found. Please log in.");
      return;
    }
  
    const confirmed = window.confirm("Are you sure you want to delete your account? This action cannot be undone.");
    if (!confirmed) return;
  
    setDeletingAccount(true);
  
    const accessToken = localStorage.getItem("access_token");
  
    try {
      const data = await deleteAccount(accessToken!); // Call the extracted function
      alert(data.message);
  
      // Optionally, redirect the user to the login page after account deletion
      localStorage.clear(); // Clear any saved user data
      navigate("/login");
    } catch (error) {
      alert("There was an issue deleting your account. Please try again.");
    } finally {
      setDeletingAccount(false);
    }
  };
  

  return (
    <div className="profile-page">
      <div className="left-column">
        <div className="user-avatar">
        <img src={avatar} alt="User Avatar" />

       
      
        </div>
       
        <div className="button-container">
        <div className="user-info">
          <p>Email: {userEmail}</p> 
          <CreditsTab credits={userCredits} language={language as "en" | "zh"} /> 

        </div>
        <button className="profile-btn" onClick={handleSubscription}>
         {text.manageSubscription}
        </button>
  

      
        <button
            className="delete-account-btn"
            onClick={handleDeleteAccount}
            disabled={deletingAccount}
          >
            {/* {deletingAccount ? "Deleting Account..." : "Delete Account"} */}
            {deletingAccount ? text.deletingAccount : text.deleteAccount}
          </button>
          
      </div>
      </div>
     

      <div className="right-column">
      
      <div className="mood-profile-container">
      {/* <div className="graph-container-text">
      <p>Mood Profile </p>
      </div> */}

<div className="chat-now-container">
      <button className="profile-btn-mood" onClick={handleMoodProfileClick}>
      {/* View Mood Profile */}
      {text.moodProfileButton}
    </button>

       <button className="profile-btn-chat" onClick={handleChatNowClick}>
       {text.chatnow}
      </button>

      </div>

    <div className="paragraph-container-text">
    <p>{text.moodProfile} </p>
    <img src={moodProfileimage} alt="mood_image"   className="mood-profile-image"/>

   </div>
   </div>

<div className="mood-profile-container">
<div className="chat-now-container">
      <button className="profile-btn-mood" onClick={handlePersonProfileClick}>
      {text.personalityProfileButton}

    </button>

    <button className="profile-btn-chat" onClick={handleChatNowClick}>
    {text.chatnow}
      </button>
      </div>

    

    <div className="paragraph-container-text">
    <p>    {text.personalityProfile} </p>
    <img src={personProfileimage} alt="mood_image"   className="mood-profile-image"/>
    

   </div>
   </div>

    </div>
    <div className="third-column">

    <div className="graph-container-text">
    <div className="voice-container">
      <button className="profile-btn-voice" onClick={handleChangeVoice}>
      {text.changeVoice}
    </button>

    <div className="paragraph-container-text">
    <p> 🔥 {text.enhanceVoice} </p>

   </div>
   </div>
  </div>

  <div className="graph-container-text">
    <div className="voice-container">
      <button className="profile-btn-voice" onClick={handleConversationHistoryClick}>
      {text.conversationHistory}
    </button>

    <div className="paragraph-container-text">
    <p>    {text.reviewConversations}</p>

   </div>
   </div>
  </div>

  <div className="graph-container-text">
    <div className="voice-container">
      <button className="profile-btn-voice" onClick={handleConversationSummaryClick}>
      {text.conversationSummary}
    </button>

    <div className="paragraph-container-text">
    <p>    {text.highlightsTakeaways}.</p>

   </div>
   </div>
  </div>
    

  </div>
    </div>


  );
};

export default ProfilePage;
