import reinventing from "../../../assets/bookclub_photos/reinventing.jpg";



const article1 = {
  id: 1,
  title: "Reinventing Your Life",
  author: "Janet S. Klosko and Jeffrey Young",
  image: reinventing, 
  content: " Identify and overcome self-defeating patterns (lifetraps) to achieve personal growth and emotional well-being",
  comments: [
    { id: 1, author: "Jane", text: "This article is so inspiring!" },
    { id: 2, author: "Mike", text: "I love the examples you provided." },
  ],
};

export default article1;