import React, { useState } from 'react';
import { usePsyfyClient } from "../../hooks/psyfyClient";

const UnsubscribePageBook = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [reason, setReason] = useState('');
  const { unsubscribeUser } = usePsyfyClient();

  const reasons = [
    'Too many emails',
    'Content not relevant',
    'No longer interested in book club',
    'Other'
  ];

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!email) {
      setError('Please enter your email address');
      return;
    }

    try {
      await unsubscribeUser(email, reason);
      setSubmitted(true);
      setError('');
    } catch (err) {
      setError('Failed to unsubscribe. Please try again.');
    }
  };
  const containerStyle = {
    fontFamily: 'Poppins, sans-serif'
  };


  if (submitted) {
    return (
      <div  style={containerStyle}  className="w-full max-w-md mx-auto mt-12 bg-white rounded shadow p-6">
        <div className="mb-4 p-4 bg-green-100 text-green-700 rounded">
          You have been successfully unsubscribed from Psychology For You Bookclub.
        </div>
        <p className="text-gray-600">
          We're sorry to see you go. You can always resubscribe by visiting our homepage.
        </p>
      </div>
    );
  }

  return (
    <div style={containerStyle}  className="w-full max-w-md mx-auto mt-12 bg-white rounded shadow p-6">
      <h1 className="text-2xl font-bold mb-6">Unsubscribe from Bookclub</h1>
      <form onSubmit={handleSubmit} className="space-y-4">
      <div className="flex flex-col gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Email Address
          </label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="block w-full p-2 border border-gray-200 rounded"
            placeholder="Enter your email"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Reason for unsubscribing (optional)
          </label>
          <select
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            className="block w-full p-2 border border-gray-200 rounded"
          >
            <option value="">Select a reason</option>
            {reasons.map((r) => (
              <option key={r} value={r}>{r}</option>
            ))}
          </select>
        </div>
        </div>

        {error && (
          <div className="p-4 bg-red-100 text-red-700 rounded">
            {error}
          </div>
        )}

<button
          type="submit"
          className="w-full bg-sky-500 text-white py-3 px-4 rounded-lg hover:bg-sky-600 transition-all duration-200 font-medium border-none"
        >
          Unsubscribe
        </button>
      </form>
    </div>
  );
};

export default UnsubscribePageBook;