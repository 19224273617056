import React, { useState, useEffect} from "react";
import "./profile.scss";
import CONFIG from "../../config";


interface Response {
    session_id: string;
    endtime: string;
    ai_replies: string;
    human_replies: string;
   
  }
  
  interface GroupedConversation {
    session_id: string;
    responses: Response[];
  }
  
  

  const ConversationHistoryPage = () => {
    const [groupedConversations, setGroupedConversations] = useState<GroupedConversation[]>([]);
    const [loading, setLoading] = useState(false);
    const [expandedConversationId, setExpandedConversationId] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
  
    const userEmail = localStorage.getItem("userEmail");

    useEffect(() => {
      getConversations();
    }, []);
  
    const getConversations = async () => {
      if (!userEmail) {
        alert("User email not found. Please log in.");
        return;
      }
  
      setLoading(true);
  
      try {
        const response = await fetch(`${CONFIG.BASE_URL}/get_conversation_history_profile`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: userEmail }),
        });
     
  
        // if (!response.ok) {
        //   throw new Error(`HTTP error! status: ${response.status}`);
        // }
        if (!response.ok) {
          let errorMsg = 'An error occurred while fetching conversations.';
          
          if (response.status === 429) {
            const errorData = await response.json();
            errorMsg = errorData.error || errorMsg;
          } else {
            try {
              const errorData = await response.json();
              errorMsg = errorData.error || errorMsg;
            } catch {
              // If parsing fails, keep the default errorMsg
            }
          }
          throw new Error(errorMsg);
        }
  
        const data: Response[] = await response.json();

     
  
        // Group conversations by session_id
        const grouped = data.reduce((acc: GroupedConversation[], current) => {
            const existing = acc.find(group => group.session_id === current.session_id);
            if (existing) {
              existing.responses.push({
                session_id: current.session_id,
                endtime: current.endtime,
                ai_replies: current.ai_replies,
                human_replies: current.human_replies,
              });
            } else {
              acc.push({
                session_id: current.session_id,
                responses: [
                  {
                    session_id: current.session_id,
                    endtime: current.endtime,
                    ai_replies: current.ai_replies,
                    human_replies: current.human_replies,
                  },
                ],
              });
            }
          return acc;
        }, []);

        grouped.sort((a, b) => {
            const dateA = new Date(a.responses[0].endtime);
            const dateB = new Date(b.responses[0].endtime);
            return dateB.getTime() - dateA.getTime(); // Descending order
          });
  
        setGroupedConversations(grouped);
      } catch (error: any) {
        console.error("Error fetching conversations:", error);
        setErrorMessage(error.message);
      } finally {
        setLoading(false);
      }
    };
  
    const toggleConversation = (id: string): void => {
      setExpandedConversationId(expandedConversationId === id ? null : id);
    };
  
    return (
      <div className="profile-background">
        {/* <div className="summary-btn-container">
          <button className="summary-btn" onClick={getConversations}>
            Get Conversations
          </button>
        </div> */}
  
        <div className="summary-form-container">
          {loading ? (
            <p>Loading conversations...</p>
          ) : groupedConversations.length === 0 ? (
            <p className="error-message">{errorMessage}</p>
          ) : groupedConversations.length === 0 ? (
            <p>No conversations found.</p>
          ) : (
            groupedConversations.map((group) => (
              <div key={group.session_id} className="summary-container">
                <div
                  onClick={() => toggleConversation(group.session_id)}
                  className="summary-header"
                >
                  <div className="summary-time">
                    {new Date(group.responses[0].endtime).toLocaleString()} {/* Display the endtime of the first response */}
                  </div>
                </div>
                {expandedConversationId === group.session_id && (
                  <div className="conversation-text">
                    {group.responses.map((response, index) => (
                      <div key={index}>
                           {index !== 0 ? ( // Check if it's not the first exchange in the session
                      <p className="conversation-human">You: {response.human_replies}</p>
                    ) : null}
                          {/* <p className="conversation-human">You: {response.human_replies}</p> */}
                        <p className="conversation-ai">Mia: {response.ai_replies}</p>
                      
                      
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))
          )}
        </div>
      </div>
    );
  };

  export default ConversationHistoryPage;