import React, { useState, useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";
import {usePsyfyClient} from "../../hooks/psyfyClient";
import Logger from "../../utils/logger";
import './login.scss'
import { supabase } from './supabaseClient'; // Make sure to import your Supabase client

// LoginForm component
const LoginForm = () => {
  const {loginUser} = usePsyfyClient();
  const [clientEmail, setClientEmail] = useState("");
  const [clientPassword, setClientPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showDownloadPopup, setShowDownloadPopup] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/android|iPad|iPhone|iPod/i.test(userAgent)) {
      setShowDownloadPopup(true);
    }
  }, []);

  const DownloadPopup = () => (
    <div className="download-popup">
      <div className="popup-content">
        <p>For full features of the app, please download our iOS app or use a PC:</p>
        <a href="https://apps.apple.com/us/app/psyfy/id6729554792" target="_blank" rel="noopener noreferrer">
          <button className="download-button">Download iOS App</button>
        </a>
        <button className="continue-button" onClick={() => setShowDownloadPopup(false)}>Continue to Web App</button>
   
      </div>
    </div>
  ); // Lines 54-65

  const handleAppleSignIn = async () => {
    try {
      // Get the Apple ID token from Apple's web authentication
      // For web, we need to use the Sign In with Apple JS framework
      const response = await (window as any).AppleID.auth.signIn();
      
      if (!response.authorization || !response.authorization.id_token) {
        throw new Error('Failed to get Apple ID token');
      }

      const identityToken = response.authorization.id_token;

      // Sign in with Supabase using the Apple identity token
      const { data, error } = await supabase.auth.signInWithIdToken({
        provider: 'apple',
        token: identityToken,
        nonce: response.authorization.nonce, // Include nonce if available
      });

      if (error) {
        throw error;
      }

      // Handle successful authentication
      console.log('Supabase Apple Sign-In success:', data);
      navigate('/profile');
      
    } catch (error) {
      console.error('Apple Sign-In Error:', error);
      if (error instanceof Error) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage('An unknown error occurred during Apple Sign In');
      }
    }
  };
  

  

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const trimmedEmail = clientEmail.trim().toLowerCase();
      const response = await loginUser(trimmedEmail, clientPassword);

      const data = response.data;

      if (data["auth"] === "1") {
        localStorage.setItem("access_token", data["access_token"]);
        localStorage.setItem("username", data["user_name"]);
        localStorage.setItem("userEmail", data["user_email"]);
        localStorage.setItem("data", JSON.stringify(data));
        localStorage.setItem("sessions", JSON.stringify(data["sessions"]));
        // Use navigate to go to /profile page
        // navigate("/chatbot");
        navigate("/profile");
      } else if (data["status"] === 409) {
        setErrorMessage("Wrong password or you haven't registered yet");
      } else {
        setErrorMessage("An error occurred during login.");
      }
    } catch (error: any) {
      if (error.response) {
        if (error.response.status === 429) {
          setErrorMessage("Too many login attempts. Please wait 1 minute and try again.");
        } else {
          setErrorMessage("An error occurred during login.");
        }
      } else {
        setErrorMessage("An error occurred while logging in.");
      }
      Logger.debug("authentication error", error);
    }
  };

  const handleSignUp = () => {
    navigate("/signup");
  };

  return (
    <div className="login-page">
      {showDownloadPopup && <DownloadPopup />}
      <div className="form">
        <form className="login-form" onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="your email"
            value={clientEmail}
            onChange={(e) => setClientEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="password"
            value={clientPassword}
            onChange={(e) => setClientPassword(e.target.value)}
          />
          <button type="submit">Log in</button>
          <button type="submit" className="sign-up-button" onClick={handleSignUp}>Sign up</button>
          {/* <button 
            type="button" 
            className="apple-sign-in-button" 
            onClick={handleAppleSignIn}
          >
            Sign in with Apple
          </button> */}
      
          {/* <GoogleLoginButton /> */}

          <p className="message">
            {/* Not registered yet? <Link to="/signup">Create an account</Link>
            <br></br> */}
            <Link to="/pwReset" className="password-reset">
              {" "}
              I forgot my password
            </Link>
          </p>
          <div>
            <p className="errorMessage">{errorMessage}</p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
