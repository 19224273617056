import React, { useState, useEffect } from "react";
import "./change_voice.scss";
import { FaPlay, FaCheckCircle, FaPause } from "react-icons/fa";
import sampleVoice1 from "../../assets/voice_sample/psyfy_google_standardC_F.mp3";
import sampleVoice2 from "../../assets/voice_sample/psyfy_google_standardB_M.mp3";
import sampleVoice3 from "../../assets/voice_sample/psyfy_google_standardD_M.mp3";
import sampleVoice4 from "../../assets/voice_sample/psyfy_google_standardF_F.mp3";
import sampleVoice5 from "../../assets/voice_sample/psyfy_google_wavenetF_F.mp3";
import sampleVoice6 from "../../assets/voice_sample/psyfy_google_wavenetB_M.mp3";
import sampleVoice7 from "../../assets/voice_sample/psyfy_google_wavenetC_F.mp3";
import sampleVoice8 from "../../assets/voice_sample/psyfy_google_wavenetD_M.mp3";
import sampleVoice9 from "../../assets/voice_sample/open_ai_sample_alloy.wav";
import sampleVoice10 from "../../assets/voice_sample/open_ai_sample_echo.wav";

import sampleVoice1c from "../../assets/voice_sample/psyfy_google_standard_C_Chinese.mp3";
import sampleVoice2c from "../../assets/voice_sample/psyfy_google_standard_B_Chinese.mp3";
import sampleVoice3c from "../../assets/voice_sample/psyfy_google_standard_D_Chinese.mp3";
import sampleVoice4c from "../../assets/voice_sample/psyfy_google_standard_A_Chinese.mp3";
import sampleVoice5c from "../../assets/voice_sample/psyfy_google_wavenet_A_Chinese.mp3";
import sampleVoice6c from "../../assets/voice_sample/psyfy_google_wavenet_B_Chinese.mp3";
import sampleVoice7c from "../../assets/voice_sample/psyfy_google_wavenet_C_Chinese.mp3";
import sampleVoice8c from "../../assets/voice_sample/psyfy_google_wavenet_D_Chinese.mp3";
import sampleVoice9c from "../../assets/voice_sample/open_ai_sample_alloy_chinese.wav";
import sampleVoice10c from "../../assets/voice_sample/open_ai_sample_echo_chinese.wav";
import { usePsyfyClient } from "../../hooks/psyfyClient";

const ChangeVoicePage = () => {
  const [selectedVoice, setSelectedVoice] = useState<string | null>(null);
  const { sendVoiceSelectionToBackend, fetchVoices, fetchUserCredits, fetchUserLanguageFromBackend} = usePsyfyClient();
  const [currentAudio, setCurrentAudio] = useState<HTMLAudioElement | null>(null);
  const [currentlyPlayingId, setCurrentlyPlayingId] = useState<string | null>(null);
  const [userCredits, setUserCredits] = useState<number | null>(null);
  const [language, setLanguage] = useState<string>("en");





  const voices = [
    { id: "en-US-Standard-C", name: "Emma", audio: sampleVoice1, type: "basic", lang: "en" },
    { id: "en-US-Standard-B", name: "Lucas", audio: sampleVoice2, type: "basic", lang: "en" },
    { id: "en-US-Standard-D", name: "Henry", audio: sampleVoice3, type: "basic", lang: "en" },
    { id: "en-US-Standard-F", name: "Violet", audio: sampleVoice4, type: "basic", lang: "en" },
    { id: "en-US-Wavenet-A", name: "Isabella", audio: sampleVoice5, type: "realistic", lang: "en" },
    { id: "en-US-Wavenet-B", name: "Liam", audio: sampleVoice6, type: "realistic", lang: "en" },
    { id: "en-US-Wavenet-C", name: "Sophia", audio: sampleVoice7, type: "realistic", lang: "en" },
    { id: "en-US-Wavenet-D", name: "Leo", audio: sampleVoice8, type: "realistic", lang: "en" },
    { id: "echo", name: "Echo", audio: sampleVoice10, type: "premium", lang: "en" },
    { id: "alloy", name: "Alloy", audio: sampleVoice9, type: "premium", lang: "en" },

    { id: "cmn-CN-Standard-C", name: "子杰", audio: sampleVoice1c, type: "basic", lang: "zh" },
    { id: "cmn-CN-Standard-B", name: "明宇", audio: sampleVoice2c, type: "basic", lang: "zh" },
    { id: "cmn-CN-Standard-D", name: "子涵", audio: sampleVoice3c, type: "basic", lang: "zh" },
    { id: "cmn-CN-Standard-A", name: "紫薇", audio: sampleVoice4c, type: "basic", lang: "zh" },
    { id: "cmn-CN-Wavenet-A", name: "雅静", audio: sampleVoice5c, type: "realistic", lang: "zh" },
    { id: "cmn-CN-Wavenet-B", name: "天翔", audio: sampleVoice6c, type: "realistic", lang: "zh" },
    { id: "cmn-CN-Wavenet-C", name: "昊天", audio: sampleVoice7c, type: "realistic", lang: "zh" },
    // { id: "cmn-CN-Wavenet-D", name: "佳宁", audio: sampleVoice8c, type: "realistic", lang: "zh" },
    // { id: "echo", name: "志明", audio: sampleVoice10c, type: "premium", lang: "en" },
    // { id: "alloy", name: "采洁", audio: sampleVoice9c, type: "premium", lang: "en" },
  ];


  const playAudio = (voiceId: string, audioUrl: string) => {
    // If something is already playing
    if (currentAudio) {
      // Check if the same voice is currently playing
      if (currentlyPlayingId === voiceId) {
        // Pause it
        currentAudio.pause();
        currentAudio.currentTime = 0;
        setCurrentAudio(null);
        setCurrentlyPlayingId(null);
        return;
      } else {
        // Pause and reset the currently playing audio if it's different
        currentAudio.pause();
        currentAudio.currentTime = 0;
      }
    }

    // Start playing the new audio
    const audio = new Audio(audioUrl);
    audio.play();
    setCurrentAudio(audio);
    setCurrentlyPlayingId(voiceId);
  };


  const handleVoiceSelect = (id: string, type: string) => {
    setSelectedVoice(id);
    sendVoiceSelectionToBackend(id, type);
  };

  useEffect(() => {
    const retrieveSelectedVoice = async () => {
      try {
        const voiceData = await fetchVoices();
        if (voiceData?.id) {
          setSelectedVoice(voiceData.id);
        } else {
          setSelectedVoice("en-US-Standard-C");
          sendVoiceSelectionToBackend("en-US-Standard-C", "basic");
        }
      } catch (error) {
        console.error("Error fetching selected voice:", error);
        setSelectedVoice("en-US-Standard-C");
        sendVoiceSelectionToBackend("en-US-Standard-C", "basic");
      }
    };

    retrieveSelectedVoice();
  }, [fetchVoices, sendVoiceSelectionToBackend]);



  useEffect(() => {
    const fetchAndSetLanguage = async () => {
      try {
        const userLanguage = await fetchUserLanguageFromBackend();
        if (userLanguage) {
          setLanguage(userLanguage === "chinese" ? "zh" : "en");
          localStorage.setItem("language", userLanguage);
        }
      } catch (error) {
        console.error("Error fetching user language:", error);
      }
    };

    fetchAndSetLanguage();
  }, [fetchUserLanguageFromBackend]);

  useEffect(() => {
    const retrieveCredits = async () => {
      try {
        const credits = await fetchUserCredits();
        setUserCredits(credits);
      } catch (error) {
        console.error("Error fetching user credits:", error);
        setUserCredits(null);
      }
    };
  
    retrieveCredits();
  }, [fetchUserCredits]);

  const renderVoiceGroup = (groupName: string, voiceType: string) => (
    <div className="voice-group">
      <h2>
        {groupName} 
        {voiceType === "basic" && (
          <span className="credit-info">(Each turn costs 2 credits)</span>
        )}
         {voiceType === "realistic" && (
          <span className="credit-info">(Each turn costs 5 credits)</span>
        )}
         {voiceType === "premium" && (
          <span className="credit-info">(Each turn costs 10 credits)</span>
        )}
      </h2>
      <div className="voice-list">

      {voices
    .filter((voice) => voice.type === voiceType && (voice.lang === language || voice.lang === "both"))

          .map((voice) => {
            const isPlaying = currentlyPlayingId === voice.id && currentAudio !== null;
            return (
              <div
                key={voice.id}
                className={`voice-item ${selectedVoice === voice.id ? "selected" : ""}`}
              >
                <p>{voice.name}</p>
                <button onClick={() => playAudio(voice.id, voice.audio)} className="play-button">
                  {isPlaying ? <FaPause /> : <FaPlay />}
                </button>
                <button
                  onClick={() => handleVoiceSelect(voice.id, voice.type)}
                  className="select-button"
                >
                  {selectedVoice === voice.id ? <FaCheckCircle /> : "Select"}
                </button>
              </div>
            );
          })}
      
   
      </div>
    </div>
  );



  return (
    <div className="change-voice-page">
      <header className="page-header">
        <h1>Change Voice</h1>
        <p>Preview and select your favorite voice for Mia</p>
        <p className="credits-display">Your Credits: {userCredits !== null ? userCredits : 'Loading...'}</p>

      </header>

      <main className="voice-container">
        {renderVoiceGroup("Basic Voices", "basic")}
        {renderVoiceGroup("Realistic Voices", "realistic")}
        {renderVoiceGroup("Premium Voices", "premium")}
      </main>

      <footer className="page-footer">
        <p>
          You have selected: {voices.find((voice) => voice.id === selectedVoice)?.name || "None"}
        </p>
     
      </footer>
    </div>
  );
};

export default ChangeVoicePage;


