import React from "react";
import MainTitle from "./MainTitle";
import Waves from "../Waves";
import IntroBlocks from "../DesignIntro";
import "./home.scss";

const Home = () => {
  return (
    <div className="home-page">
    
      <MainTitle />
      <Waves />
      <IntroBlocks />
    </div>
  );
};

export default Home;
