import "normalize.css/normalize.css";
import "../css/style_chatbot.scss";
import React from "react";

import {
    Route,
    Routes,
} from "react-router-dom";
import Intro from "./intro";
import ResearchPage from "./research";
import Login from "./login";
import Home from "./home";
import {ProfilePage, ConversationHistoryPage, SummaryPage, MoodProfilePage, PersonalityProfilePage, ChangeVoicePage} from "./profile";
import SignUp from "./signup";
import Chatbot from "./Chatbot/ChatbotIndex";
import Support from "./support";
import {Bookclub, UnsubscribePageBook} from "./bookclub";
import EmotionRegulationToolkit from "./BehavioralActivation";
import PrivacyPolicyPage from "../content/PrivacyPolicyPage";
import TermsConditionPage from "../content/TermsConditionPage";
// import BuyCredit from "./PurchasePlans";
import { ResetPasswordPage, PasswordResetRequestForm } from './pwreset';

// import SuccessPage from "./PaymentSuccess"
// import StripeCheckoutComponent from './StripeCheckoutComponent';
import { SuccessPage, StripeCheckoutComponent, BuyCredit } from './payment';
import stripePromise from '../hooks/stripe';
import { Elements } from '@stripe/react-stripe-js';
import Guidelines from "./Guideline";


const StandardRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/intro" element={<Intro />} />
            <Route path="/research" element={<ResearchPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/chatbot" element={<Chatbot />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/conversation" element={<ConversationHistoryPage />} />
            <Route path="/summary" element={<SummaryPage />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/voices" element={<ChangeVoicePage />} />

            <Route path="/emotionReg" element={<EmotionRegulationToolkit />} />
            <Route path="/privacy" element={<PrivacyPolicyPage />} />
            <Route path="/terms" element={<TermsConditionPage />} />
            <Route path="/credits" element={<BuyCredit />} />
            <Route path="/pwReset" element={<PasswordResetRequestForm />} />
            <Route path="/reset_password" element={<ResetPasswordPage />} />
            {/* <Route path="/unsubscribe" element={<UnsubscribePage />} /> */}
            <Route path="/paymentsuccess" element={<SuccessPage />} />
            <Route path="/guideline" element={<Guidelines />} />
            <Route path="/support" element={<Support />} />
            <Route path="/moodprofile" element={<MoodProfilePage />} />
            <Route path="/bookclub" element={<Bookclub />} />
            <Route path="/bookclub_unsubscribe" element={<UnsubscribePageBook />} />
            <Route path="/personprofile" element={<PersonalityProfilePage />} />
            {/* <Route path="/payment" element={<PaymentPage />} /> */}
            <Route path="/checkout" element={<Elements stripe={stripePromise}><StripeCheckoutComponent /></Elements>} />
        </Routes>
    );
};

export default StandardRoutes;
