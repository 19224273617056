import React, { useState, useEffect, useRef} from "react";
import MessageItem from "./MessageItem";


const MessageList = ({
  messages,
  onRating,
  endOfMessagesRef,
  onRewriteInitiate,
  isExperiment,
  isTyping
}) => {

  const [showTyping, setShowTyping] = useState(false);
  const typingRef = useRef(null);

  // delay typing
    // delay typing
    useEffect(() => {
      let typingTimeout;
      if (isTyping) {
        // Set a timeout to show typing after 1 second
        typingTimeout = setTimeout(() => {
          setShowTyping(true);
          if (typingRef.current) {
            typingRef.current.scrollIntoView({ behavior: "smooth" });
          }
        }, 2000);
      } else {
        // Immediately hide typing indicator when isTyping is false
        setShowTyping(false);
      }
  
      return () => clearTimeout(typingTimeout); // Clear timeout if component unmounts or isTyping changes
    }, [isTyping]);
  



  return (
    <div className="message-list">
      {/* {messages.map((message) => ( */}
      {Array.isArray(messages) &&
        messages.map((message) => (
          <MessageItem
            key={message.id}
            message={message}
            onRating={onRating}
            onRewriteInitiate={onRewriteInitiate}
            isExperiment={isExperiment}
          />
        ))}
              {/* Typing indicator */}
      {showTyping && (
        <div className="msg left-msg typing-indicator" ref={typingRef}>
          <div className="msg-bubble">
            <div className="msg-info">
              <div className="msg-info-name">Mia</div>
              <div className="msg-info-time">
                {new Date().toLocaleTimeString()}
              </div>
            </div>
            <div className="msg-text">
              Mia is thinking
              <span className="dot-flashing">...</span>
            </div>
          </div>
        </div>
      )}



      <div ref={endOfMessagesRef} />
    </div>
  );
};

export default MessageList;
