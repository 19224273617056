import React, { useState, useEffect } from 'react';
import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';
import './mood_profile.scss';
import { usePsyfyClient } from '../../hooks/psyfyClient';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const PersonalityProfilePage: React.FC = () => {
  const { getPersonalityProfile } = usePsyfyClient();

  const [bigFiveScores, setBigFiveScores] = useState({
    openness: 0,
    conscientiousness: 0,
    extraversion: 0,
    agreeableness: 0,
    stability: 0,
  });
  const [visionaryDescription, setVisionaryDescription] = useState('');
  const [personalityType, setPersonalityType] = useState('');
  const [traitDescriptions, setTraitDescriptions] = useState<Record<string, { score: string; reason: string }>>({});
  const [loading, setLoading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);


  // useEffect(() => {
  //   const cachedProfile = localStorage.getItem('personalityProfile');
  //   if (cachedProfile) {
  //     updateProfileStates(JSON.parse(cachedProfile));
  //   }
  // }, []);
  // useEffect(() => {
  //   const fetchProfileOnLoad = async () => {
  //     const cachedProfile = localStorage.getItem('personalityProfile');

  //     // console.log('this is the cached profile', cachedProfile)
  //     if (cachedProfile) {
  //       // Use the cached profile to avoid unnecessary fetch
  //       updateProfileStates(JSON.parse(cachedProfile));
  //     } else {
  //       // Fetch the profile if no cached data exists
  //       await fetchPersonalityProfile();
  //     }
  //   };
  
  //   fetchProfileOnLoad(); // Trigger the function when the component mounts
  // }, []);
  useEffect(() => {
    const fetchProfileOnLoad = async () => {
      // Always fetch the profile from the backend
      await fetchPersonalityProfile();
    };
  
    fetchProfileOnLoad(); // Trigger the function when the component mounts
  }, []);
  

  const fetchPersonalityProfile = async () => {
    setLoading(true);
    try {
      localStorage.removeItem('personalityProfile'); // Clear the cache before fetching
      console.log('getting personality profile')

      const profile = await getPersonalityProfile();
      localStorage.setItem('personalityProfile', JSON.stringify(profile)); // Cache the result
      
      updateProfileStates(profile);
    } catch (error) {
      console.error('Error fetching personality profile:', error);
    } finally {
      setLoading(false);
    }
  };

  const updateProfileStates = (profile: {
    scores: { Openness: number; Conscientiousness: number; Extroversion: number; Agreeableness: number; Stability: number };
    personality_type: { name: string; description: string };
    description: {
      Openness: { score: string; reason: string };
      Conscientiousness: { score: string; reason: string };
      Extroversion: { score: string; reason: string };
      Agreeableness: { score: string; reason: string };
      Stability: { score: string; reason: string };
    };
  }) => {
    const scores = {
      openness: profile.scores.Openness,
      conscientiousness: profile.scores.Conscientiousness,
      extraversion: profile.scores.Extroversion,
      agreeableness: profile.scores.Agreeableness,
      stability: profile.scores.Stability,
    };
    setBigFiveScores(scores);

    setPersonalityType(profile.personality_type.name);
    setVisionaryDescription(profile.personality_type.description);

    const descriptions = {
      openness: {
        score: profile.description.Openness.score,
        reason: `Reason: ${profile.description.Openness.reason}`,
      },
      conscientiousness: {
        score: profile.description.Conscientiousness.score,
        reason: `Reason: ${profile.description.Conscientiousness.reason}`,
      },
      extraversion: {
        score: profile.description.Extroversion.score,
        reason: `Reason: ${profile.description.Extroversion.reason}`,
      },
      agreeableness: {
        score: profile.description.Agreeableness.score,
        reason: `Reason: ${profile.description.Agreeableness.reason}`,
      },
      stability: {
        score: profile.description.Stability.score,
        reason: `Reason: ${profile.description.Stability.reason}`,
      },
    };
    setTraitDescriptions(descriptions);
  };

  const data = {
    labels: [
      'Openness',
      'Conscientiousness',
      'Extraversion',
      'Agreeableness',
      'Stability',
    ],
    datasets: [
      {
        label: 'Your Personality Profile',
        data: Object.values(bigFiveScores),
        backgroundColor: 'rgba(34, 202, 236, 0.2)',
        borderColor: 'rgba(34, 202, 236, 1)',
        borderWidth: 2,
        pointBackgroundColor: 'rgba(34, 202, 236, 1)',
      },
    ],
  };

  const options = {
    scales: {
      r: {
        angleLines: {
          display: true,
        },
        suggestedMin: 0,
        suggestedMax: 100,
        ticks: {
          stepSize: 20,
          font: {
            size: 18, // Adjust this for the tick labels
          },
        },
        pointLabels: {
          font: {
            size: 16, // Adjust this for the radial labels
          },
          color: '#000', // Optional: Set point label color
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  useEffect(() => {
    const cachedProfile = localStorage.getItem('personalityProfile');
    if (cachedProfile) {
      updateProfileStates(JSON.parse(cachedProfile));
    } else {
      fetchPersonalityProfile(); // Automatically fetch if no cached profile
    }
  }, []);

  return (
    <div className="personality-profile-page">
      <h2>Personality Profile</h2>
      <p className="info-message">
          Your personality profile will update as you chat with Mia more. 
        </p>
      

      <div className="profile-layout">
     
        <div className="single-column">
          <div className="chart-container">
            <Radar data={data} options={options} />
          </div>
          <h3>You are a {personalityType}</h3>
          <div className="visionary-text">
            <p>{visionaryDescription}</p>
          </div>
          <div className="trait-descriptions">
            {Object.entries(traitDescriptions).map(([trait, { score, reason }]) => (
              <div key={trait} className="trait">
                <h3>
                  {trait.charAt(0).toUpperCase() + trait.slice(1)} - <span className="score">{score}</span>
                </h3>
                <p>{reason}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalityProfilePage;


