import React, { useState, useEffect} from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {usePsyfyClient} from "../../hooks/psyfyClient";
import TermsAndConditions from "../../content/TermsContent";
import { supabase } from '../login/supabaseClient';
import { v4 as uuidv4 } from "uuid";
// import Logger from "../../utils/logger";


const SignUpForm = () => {
  const { checkUserIP, signupUser, handleLanguageChange, sendLanguageToBackend} = usePsyfyClient();
  const [clientEmail, setClientEmail] = useState("");
  const [clientPassword, setClientPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isOver18, setIsOver18] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [capacityReached, setCapacityReached] = useState(false);
  const [isRegionBlocked, setIsRegionBlocked] = useState(false);
  // const user = "user";
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false); 

  const [selectedLanguage, setSelectedLanguage] = useState("english");
  const [showDownloadPopup, setShowDownloadPopup] = useState(false);
  const navigate = useNavigate();




  const TermsModal: React.FC<TermsModalProps> = ({ onClose, onAgree }) => {
    return (
      <div className="modal">
        <div className="modal-content">
          <TermsAndConditions />
          <div className="modal-actions">
            <div className="button-container">
              <button className="agree-button" onClick={onAgree}>
                Agree
              </button>
              <button className="disagree-button" onClick={onClose}>
              Disagree
            </button>
            </div>
            
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/android|iPad|iPhone|iPod/i.test(userAgent)) {
      setShowDownloadPopup(true);
    }
  }, []);
  


  useEffect(() => {
    const currentState = uuidv4();
    localStorage.setItem('appleSignInState', currentState);

    (window as any).AppleID.auth.init({
      clientId: 'ai.psyfy.websignin',
      scope: 'email name',
      redirectURI: 'https://tkwnpbneskkerghevrka.supabase.co/auth/v1/callback',


      
      state: currentState,
    });
  }, []);

  const DownloadPopup = () => (
    <div className="download-popup">
      <div className="popup-content">
        <p>For full features of the app, please download our iOS app or use a PC:</p>
        <a href="https://apps.apple.com/us/app/psyfy/id6729554792" target="_blank" rel="noopener noreferrer">
          <button className="download-button">Download iOS App</button>
        </a>
        <button className="continue-button" onClick={() => setShowDownloadPopup(false)}>Continue to Web App</button>
   
      </div>
    </div>
  ); // Lines 54-65


  const signInWithApple = async () => {
    if (!isOver18 || !agreedToTerms) {
      setErrorMessage(!isOver18 ? "Must be over 18" : "Must agree to terms");
      return;
    }
  
    try {
      const { error } = await supabase.auth.signInWithOAuth({
        provider: 'apple',
        options: {
          redirectTo: 'https://psyfy.ai/profile',
        },
      });
  
      if (error) throw error;
  
      // No need to handle user data here since it's a redirect flow.
    } catch (error) {
      console.error('Sign-in error:', error);
      setErrorMessage(error instanceof Error ? error.message : 'Sign-in failed');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleSignInRedirect = async () => {
      const { data, error } = await supabase.auth.getSession();

      if (error) {
        console.error('Session retrieval error:', error);
        setErrorMessage('Failed to retrieve session');
        return;
      }
      if (data.session) {
        if (data.session.user.email) {
          localStorage.setItem("userEmail", data.session.user.email);
        } else {
          throw new Error('Email is undefined');
        }
        localStorage.setItem("access_token", data.session.access_token);

        // Proceed with the signup flow
        const postData = new FormData();
        postData.append('language', 'english');
        if (data.session.user.email) {
          postData.append('email', data.session.user.email);
        } else {
          throw new Error('Email is undefined');
        }

        try {
          const response = await signupUser(postData);  

          if (response.ok) {
            navigate('/profie');  // Redirect to the user's dashboard after signup
          } else {
            throw new Error('Signup failed');
          }
        } catch (error) {
          if (error instanceof Error) {
            setErrorMessage(error.message);
          } else {
            setErrorMessage('An unknown error occurred');
          }
        }
      }
    };

    handleSignInRedirect();
  }, [navigate]);

  const LoadingPopup = () => (
    <div className="loading-popup">
      <div className="loading-content">
      {errorMessage ? (
          <>
            <p>{errorMessage}</p>
            <button onClick={() => window.location.reload()}>Retry</button>
          </>
        ) : (
          <p>Please wait...</p>
        )}

      </div>
    </div>
  );



  // Handle checkbox directly to manage Terms Modal visibility
  const handleTermsCheckboxChange = () => {
    setShowTermsModal(!showTermsModal);
  };

  const handleAgree = async () => {
    setAgreedToTerms(true);
    setShowTermsModal(false);
  };

  const handleDisagree = () => {
    setShowTermsModal(false);
    setAgreedToTerms(false);
  };



  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Reset previous error messages.
    setErrorMessage("");

    if (!isOver18) {
      setErrorMessage("You must confirm that you are over 18 years old.");
      return;
    }

    if (!agreedToTerms) {
      setErrorMessage("You must agree to the terms and conditions to sign up.");
      return;
    }

    setLoading(true);

    const postData = new FormData();
    postData.append("clientEmail", clientEmail.trim().toLowerCase()); 
    postData.append("pw1", clientPassword);
    postData.append("agreedToTerms", JSON.stringify(agreedToTerms));
    postData.append("language", selectedLanguage);

    let data;

    setIsButtonDisabled(true); // Disable the button on form submit
    setTimeout(() => {
      setIsButtonDisabled(false); // Re-enable the button after 2 minutes
    }, 50000); 

    try {

      const response = await signupUser(postData);      

    
        if (!response.data) {
            throw new Error("No data received from the server. Check the server response format.");
        }
      const data = response.data;

      setLoading(false);
      
    switch (response.status) {
      case 200:
          // Success case, data should have all required fields
          localStorage.setItem("userEmail", data.clientEmail);
          localStorage.setItem("access_token", data.access_token);
          await sendLanguageToBackend(selectedLanguage);

          // Call handleLanguageChange to update the language after signup
          await handleLanguageChange(selectedLanguage, (newLang: string) => {
            console.log(`Language changed to: ${newLang}`);
          });

          navigate(capacityReached ? "/profile" : "/profile");
          break;
      case 400:
          setErrorMessage("Email validation failed. Please provide a deliverable email address.");
          break;
      case 402:
            setErrorMessage("Email not deliverable. Please provide a deliverable email address.");
            break;
      case 403:
          setErrorMessage("User already exists. Please try logging in.");
          break;
      case 409:
          setErrorMessage("Wrong password format. Please follow the required format.");
          break;
      default:
          setErrorMessage("There was a problem with your signup attempt. Please check your email and try again.");
          break;
  }
} catch (error) {
  console.error("Error during signup:", error);
  if (axios.isAxiosError(error)) { 
  if (error.response) {
      // Handling errors based on the HTTP status code from the server response
      switch (error.response.status) {
          case 400:
              setErrorMessage("Bad request. " + error.response.data.message);
              break;
          case 402:
              setErrorMessage("Email not deliverable. Please provide a deliverable email address.");
              break;
          case 403:
              setErrorMessage("Forbidden. " + error.response.data.message);
              break;
          case 409:
              setErrorMessage("Conflict. " + error.response.data.message);
              break;
          default:
              setErrorMessage("An unexpected error occurred. Please try again later.");
              break;
      }
  } else {
      // Handle network errors or errors where the response is not available
      setErrorMessage("An error occurred during signup. Please check your network and try again later.");
  }
}
};
};


  if (isRegionBlocked) {
    return (
      <div className="centered-wrapper">
        <div className="block-message">
          <p>{errorMessage}</p>
          <p>
            Contact <a href="mailto:psyfy@psyfy.ai">psyfy@psyfy.ai</a> if you
            want to try our service.
          </p>
        </div>
      </div>
    );
  }

  if (showTermsModal) {
    return <TermsModal onClose={handleDisagree} onAgree={handleAgree} />;
  }

  return (
    <div className="login-page">
       {loading && <LoadingPopup />}
       {showDownloadPopup && <DownloadPopup />}
       
      <div className="form">
        {capacityReached && ( // Conditionally render the waitlist message
          <p>
            We have currently reached our testing capacity. You have
            been placed on a waiting list and will be notified via email when a
            spot becomes available. Thank you for your patience.
          </p>
       
        )}
        <form className="login-form" onSubmit={handleSubmit}>
         <p>
            Please ensure your password consists of a minimum of six characters
            including at least one numeric digit, one uppercase letter, and one
            special symbol.
          </p>
          <input
            type="text"
            placeholder="your email"
            value={clientEmail}
            onChange={(e) => setClientEmail(e.target.value)}
          />

          <input
            type="password"
            placeholder="password"
            value={clientPassword}
            onChange={(e) => setClientPassword(e.target.value)}
          />

          <div className="checkbox-container">
            <input
              className="checkbox-field"
              type="checkbox"
              checked={agreedToTerms}
              readOnly={showTermsModal}
              onChange={handleTermsCheckboxChange} 
            />
            <label className="checkbox-label">
              I agree to the Terms and Conditions
            </label>
          </div>

          <div className="checkbox-container">
            <input
              className="checkbox-field"
              type="checkbox"
              checked={isOver18}
              onChange={(e) => setIsOver18(e.target.checked)}
            />
            <label className="checkbox-label">
              I declare that I am over 18 years old
            </label>
          </div>

          <div className="language-dropdown">
            <label className="language-dropdown-label">Select your preferred chat language:</label>
            <select
              value={selectedLanguage}
              onChange={(e) => setSelectedLanguage(e.target.value)}
            >
              <option value="english">English</option>
              <option value="chinese">中文</option>
            </select>
          </div>
          {/* <button onClick={signInWithApple}>
            Sign in with Apple
          </button> */}

          {/* <button 
            type="button"
            className="apple-sign-in-button"
            onClick={handleAppleSignIn}
            disabled={isButtonDisabled}
          >
            Sign up with Apple
          </button> */}


          <button type="submit" disabled={isButtonDisabled}>Sign up</button>

          <div>
            <p className="errorMessage">{errorMessage}</p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUpForm;

function str(arg0: string): any {
  throw new Error("Function not implemented.");
}
