import "normalize.css/normalize.css";
import "./css/style_chatbot.scss";
import React, {useEffect, useState} from "react";
import StandardPage from "./components/StandardPage";
import {BrowserRouter} from "react-router-dom";
import { isMobile, isTablet } from "react-device-detect";
import './css/global.scss'

const handleTouch = (e: React.TouchEvent) => {
  e.preventDefault();
};

const App = () => {
  // useEffect(() => {
  //   const userAgent = navigator.userAgent || navigator.vendor;

  //   // Check if the device is an iPhone
  //   const isIphone = /iPhone/i.test(userAgent);

  //   if (isIphone) {
  //     // Redirect to the App Store for iPhone users
  //     window.location.href = 'https://apps.apple.com/us/app/psyfy/6729554792';
  //   } else if (isMobile || isTablet) {
  //     // Redirect non-iOS mobile or tablet users to mobile.psyfy.ai
  //     const currentHost = window.location.host;
  //     const pathName = window.location.pathname + window.location.search + window.location.hash;

  //     if (currentHost !== 'mobile.psyfy.ai') {
  //       window.location.href = `${window.location.protocol}//mobile.psyfy.ai${pathName}`;
  //     }
  //   }
  // }, []);


  // useEffect(() => {
  //   const userAgent = navigator.userAgent || navigator.vendor;
    
  //   // Check if the device is an iPhone
  //   const isIphone = /iPhone/i.test(userAgent);

  //   if (isIphone) {
  //     // Redirect to the App Store for iPhone users
  //     window.location.href = 'https://apps.apple.com/us/app/psyfy/6729554792';
  //   }
  // }, []);
 
  // useEffect(() => {
  //   if (isMobile || isTablet) {
  //     // Replace with your actual App Store URL
  //     window.location.href = 'https://apps.apple.com/us/app/psyfy/6729554792';
  //   }
  // }, []);

  // useEffect(() => {
  //   const currentHost = window.location.host;
  //   const pathName = window.location.pathname + window.location.search + window.location.hash;
  //   const targetHost = (isMobile || isTablet) ? 'mobile.psyfy.ai' : 'www.psyfy.ai';

  //   if (currentHost !== targetHost) {
  //     window.location.href = `${window.location.protocol}//${targetHost}${pathName}`;
  //   }
  // }, []);
  
  return (
    <BrowserRouter>
  
      {/* <RedirectHandler /> */}
      {/* <div onTouchStart={handleTouch} onTouchMove={handleTouch}></div> */}
      <StandardPage/>
    </BrowserRouter>
  );
};

export default App;
