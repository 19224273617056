import React, { useState } from "react";
import "./bookclub.scss";

interface Comment {
  id: number;
  author: string;
  text: string;
  replies: Comment[];
}

interface Article {
  id: number;
  title: string;
  image: string;
  author: string;
  content: string;
  comments: Comment[];
}

// Dynamically import all articles from the articles folder
const importArticles = (): Article[] => {
  const context = (require as any).context("./articles", false, /\.js$/);
  return context.keys().map((key: any) => ({
    ...context(key).default,
    comments: [], // Initialize with empty comments
  }));
};

const Bookclub = () => {
  const [articles, setArticles] = useState<Article[]>(importArticles());
  const [mainComment, setMainComment] = useState("");
  const [replyTexts, setReplyTexts] = useState<{ [commentId: number]: string }>(
    {}
  );
  const [name, setName] = useState("");
  const [replyNames, setReplyNames] = useState<{ [commentId: number]: string }>(
    {}
  );
  const [activeArticleId, setActiveArticleId] = useState<number | null>(null);
  const [replyingToCommentId, setReplyingToCommentId] = useState<number | null>(null);

  const handleCommentSubmit = (
    articleId: number,
    parentCommentId: number | null = null
  ) => {
    const commentText =
      parentCommentId === null ? mainComment : replyTexts[parentCommentId] || "";
    const commenterName =
      parentCommentId === null ? name : replyNames[parentCommentId] || "";

    if (commentText.trim() === "") return;

    if (commenterName.trim() === "") {
      alert("Please enter your name before submitting.");
      return;
    }

    setArticles((prevArticles) =>
      prevArticles.map((article) =>
        article.id === articleId
          ? {
              ...article,
              comments: addNestedComment(
                article.comments,
                parentCommentId,
                {
                  id: Date.now(),
                  author: commenterName,
                  text: commentText,
                  replies: [],
                }
              ),
            }
          : article
      )
    );

    if (parentCommentId === null) {
      setMainComment("");
      setName("");
    } else {
      setReplyTexts((prevReplyTexts) => ({
        ...prevReplyTexts,
        [parentCommentId]: "",
      }));
      setReplyNames((prevReplyNames) => ({
        ...prevReplyNames,
        [parentCommentId]: "",
      }));
    }
    setReplyingToCommentId(null);
  };

  const addNestedComment = (
    comments: Comment[],
    parentCommentId: number | null,
    newComment: Comment
  ): Comment[] => {
    if (!parentCommentId) {
      return [...comments, newComment];
    }

    return comments.map((comment) =>
      comment.id === parentCommentId
        ? {
            ...comment,
            replies: addNestedComment(comment.replies, null, newComment),
          }
        : { ...comment, replies: addNestedComment(comment.replies, parentCommentId, newComment) }
    );
  };

  const renderComments = (comments: Comment[], articleId: number) => {
    return (
      <ul>
        {comments.map((comment) => (
          <li key={comment.id}>
            <strong>{comment.author}:</strong> {comment.text}
            <div className="comment-actions">
              <button onClick={() => setReplyingToCommentId(comment.id)}>Reply</button>
            </div>
            {replyingToCommentId === comment.id && (
              <div className="reply-input">
                <input
                  type="text"
                  value={replyNames[comment.id] || ""}
                  onChange={(e) =>
                    setReplyNames((prev) => ({
                      ...prev,
                      [comment.id]: e.target.value,
                    }))
                  }
                  placeholder="Enter your name"
                  className="name-input"
                />
                <textarea
                  value={replyTexts[comment.id] || ""}
                  onChange={(e) =>
                    setReplyTexts((prev) => ({
                      ...prev,
                      [comment.id]: e.target.value,
                    }))
                  }
                  placeholder="Write your reply here..."
                ></textarea>
                <button
                  onClick={() => handleCommentSubmit(articleId, comment.id)}
                >
                  Submit Reply
                </button>
              </div>
            )}
            {comment.replies.length > 0 && renderComments(comment.replies, articleId)}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="bookclub-container">
      <h1>Welcome to Psychology For You Bookclub</h1>
      <p>Engage in meaningful discussions about our latest articles.</p>

    <div className="articles-info">
    
        {articles.map((article) => (
          <div key={article.id} className="article">
             <div className="row">
            <h2>{article.title}</h2>
            <img
              src={article.image}
              alt={`Book cover of ${article.title}`}
              className="author-photo"
            />
              </div>
            <p className="author">By {article.author}</p>
            <p>{article.content}</p>
    

            <button
              onClick={() =>
                setActiveArticleId(
                  activeArticleId === article.id ? null : article.id
                )
              }
            >
              {activeArticleId === article.id ? "Hide Comments" : "View Comments"}
            </button>

            {activeArticleId === article.id && (
              <div className="comments-section">
                <h3>Comments</h3>
                {renderComments(article.comments, article.id)}

                <div className="comment-input">
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter your name"
                    className="name-input"
                  />
                  <textarea
                    value={mainComment}
                    onChange={(e) => setMainComment(e.target.value)}
                    placeholder="Write your comment here..."
                  ></textarea>
                  <button onClick={() => handleCommentSubmit(article.id)}>
                    Submit
                  </button>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Bookclub;
