import React, { useState } from "react";
import profile from "../assets/moodProfile.jpg";
import memory from "../assets/personProfile.jpg";
import color_brain from "../assets/color_brain.jpg";
import brain from "../assets/brain.jpg";
import DavidImage from "../images/David.jpeg";

const IntroBlocks: React.FC = () => {
  return (
    <div className="blocks-container">

<div className="block block-strategy">
        {/* <img src={brain} alt="Description of Image 2" /> */}

        <h2 className="block-header">Transdiagnostic Strategies for Mental Well-Being</h2>
        <div className="text-area-2">

        Psyfy is an AI mental wellness chatbot that provides self-help guidance. 
        The strategies that Psyfy teaches are transdiagnostic, which means
          they are relevant and helpful across a wide range of different mental
          health issues.   
        </div>

        <img src={color_brain}/>
      </div>

    

      <div className="block block-feature">
        {/* <img src={brain} alt="Description of Image 2" /> */}

  
        <div className="text-area-feature">
        <h2 className="block-header-feature">Key Features</h2>

        <ul className="features-list">
    <li>
      A built-in  <span className="highlight">Memory System </span> to remember important details about your life 
      experiences, goals you have set, and preferences, creating a personalized 
      and tailored interaction.
    </li>
    <br></br>
    <li>
    <span className="highlight">Mood Profile</span> and <span className="highlight">Personality Profile</span>  deduced from your conversations with Psyfy.
    </li>
    <br></br>
    <li>
    <span className="highlight">Summary </span>of each conversation—your entire conversation history can serve 
      as journal materials.
    </li>
  </ul>
        </div>

      </div>

      <div className="block block-cognitive">
        {/* <img src={brain} alt="Description of Image 2" /> */}
        <div className="text-area-dave">
          Psyfy offers personalized coaching in Cognitive Behavior Therapy (CBT)
          strategies, designed by our clinical psychologist David Preece, to
          help you cope with stress and improve your well-being. 

          <br></br>
          <br></br>
{/*           
          These strategies are designed to help you improve your well-being, build
          healthier habits, and achieve your personal and professional goals.
          With customizable feedback and reminders, Psyfy allows you to stay on
          track with your journey towards a more fulfilling and balanced life.
          */}
        </div>
        <img src={DavidImage} alt="Description of Image 2" />
        <h3 className="block-header-3">David Preece </h3>
        <h3 className="block-header-4">David is our clinical psychologist whose insights and research significantly shaped the development of Psyfy. </h3>
      </div>



      <div className="block block-profile">

        <div className="text-area">
        <h2 className="block-header-2">Mood Profile</h2>
          Psyfy offers a user profile. In this profile, you can track your
          progress, see a summary of each conversation, monitor your mood
          fluctuations, and stay updated on the strategies you are learning.
          <br></br>
          <br></br>
          
          By tracking your mood, you can easily reflect on your growth and
          see how your efforts are positively impacting your mental health
          journey.
        </div>
        <img src={profile} alt="Description of Image 1" />
      </div>


      <div className="block block-person">
      <img src={memory} alt="Description of Image 2" />
        <div className="text-area">
        <h2 className="block-header-2">Personality Profile</h2>
      
        Discover who you are through:
       
        <ul className="features-list">
        <br></br>

        <li>
          Big 5 Personality Insights: Visualize your scores in the Big 5 traits with a detailed graph.
        </li>
        <br></br>
 
          <li>
          Personalized Results: Receive tailored explanations of your personality.
          </li>
          <br></br>
        
          <li>
          Dynamic Growth: Be assigned one of 40 personality types, with the profile updating as Mia learns about you.
          </li>  
       
       </ul>
       </div>
      </div>

   

  
   
    </div>
  );
};

export default IntroBlocks;


