import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend,
} from 'chart.js';
import './mood_profile.scss';
import { usePsyfyClient } from '../../hooks/psyfyClient'; // Import the API module

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const MoodProfilePage: React.FC = () => {
  const { getMood } = usePsyfyClient();
  const [days, setDays] = useState<string[]>([]); // Initialize as empty arrays
  const [wellness, setWellness] = useState<number[]>([]);
  const [stress, setStress] = useState<number[]>([]);
  const [tension, setTension] = useState<number[]>([]);
  const [discouragement, setDiscouragement] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleGetMood = async () => {
    setLoading(true); 
    setShowModal(true);
    try {
      const moodResponse = await getMood();

  
      // Extract the data array from the response object
      const moodDataArray = moodResponse.data;
  
      // Create a Map to hold the latest entry per date
      const moodDataByDate = new Map<string, any>();
  
      moodDataArray.forEach((item: any) => {
        const date = new Date(item.timestamp).toLocaleDateString(); // Format the date
        const timestamp = new Date(item.timestamp).getTime(); // Convert timestamp to milliseconds
  
        if (moodDataByDate.has(date)) {
          const existingItem = moodDataByDate.get(date);
          const existingTimestamp = new Date(existingItem.timestamp).getTime();
  
          if (timestamp > existingTimestamp) {
            // Current item is more recent; update the map
            moodDataByDate.set(date, item);
          }
        } else {
          // No entry for this date yet; add it
          moodDataByDate.set(date, item);
        }
      });
  
      // Extract the data from moodDataByDate and sort the dates
      const sortedDates = Array.from(moodDataByDate.keys()).sort(
        (a, b) => new Date(a).getTime() - new Date(b).getTime()
      );
  
      const newDays: string[] = [];
      const newWellness: number[] = [];
      const newStress: number[] = [];
      const newTension: number[] = [];
      const newDiscouragement: number[] = [];
  
      sortedDates.forEach((date) => {
        const item = moodDataByDate.get(date);
        newDays.push(date);
        newWellness.push(item.wellness);
        newStress.push(item.stress);
        newTension.push(item.tension);
        newDiscouragement.push(item.discouragement);
      });
  
      // Update the state variables with the new data
      setDays(newDays);
      setWellness(newWellness);
      setStress(newStress);
      setTension(newTension);
      setDiscouragement(newDiscouragement);
    } catch (error) {
      console.error('Failed to fetch mood data:', error);
      alert('Failed to fetch mood data. Please try again later.');
    } finally {
      setLoading(false); // Hide the loading modal
      setShowModal(false);
    }
  };

  const positiveAffectData = {
    labels: days,
    datasets: [
      {
        label: 'Wellness',
        data: wellness,
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderWidth: 2,
        tension: 0.4,
        pointRadius: 6, // Increase the size of dots
        pointHoverRadius: 8,
      },
    ],
  };

  const negativeAffectData = {
    labels: days,
    datasets: [
      {
        label: 'Dejected/Discouraged',
        data: discouragement,
        borderColor: 'rgba(255, 206, 86, 1)',
        backgroundColor: 'rgba(255, 206, 86, 0.2)',
        borderWidth: 2,
        tension: 0.4,
      },
      {
        label: 'Restless/Irritable',
        data: stress,
        borderColor: 'rgba(255, 99, 132, 1)',
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderWidth: 2,
        tension: 0.4,
        pointRadius: 6, // Increase the size of dots
        pointHoverRadius: 8,
      },
      {
        label: 'Fearful/Physically Tense',
        data: tension,
        borderColor: 'rgba(54, 162, 235, 1)',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderWidth: 2,
        tension: 0.4,
      },
    
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: false,
        // text: 'Mood Profile',
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date', // Updated to 'Date'
        },
      },
      y: {
        title: {
          display: true,
          text: 'Scores',
        },
        min: 0,
        max: 100,
      },
    },
  };
  useEffect(() => {
    const cachedData = JSON.parse(localStorage.getItem('moodProfileData') || '{}');
    if (cachedData.days) setDays(cachedData.days);
    if (cachedData.wellness) setWellness(cachedData.wellness);
    if (cachedData.stress) setStress(cachedData.stress);
    if (cachedData.tension) setTension(cachedData.tension);
    if (cachedData.discouragement) setDiscouragement(cachedData.discouragement);
    // Fetch the mood data on component mount
    handleGetMood();
  }, []);


  useEffect(() => {
    const dataToCache = {
      days,
      wellness,
      stress,
      tension,
      discouragement,
    };
    localStorage.setItem('moodProfileData', JSON.stringify(dataToCache));
  }, [days, wellness, stress, tension, discouragement]);

  return (
    <div className="mood-profile-page">
    <h2>Mood Profile</h2>
    {/* <div className="button-container">
      <button onClick={handleGetMood} className="get-mood-button" disabled={loading}>
        {loading ? 'Loading...' : 'Get Mood Scores'}
      </button>
      <p className="description">
        Mood scores are session-based. The first session starts from when you first retrieve the mood score.
      </p>
    </div> */}
    {showModal && (
      <div className="modal-overlay">
        <div className="modal">
          <h3>Processing</h3>
          <p>This process may take several minutes. Please be patient.</p>
        </div>
      </div>
    )}
    <div className="chart-container">
      <div className="positive-chart">
      <h3>Overall Wellness Score</h3>
        <Line data={positiveAffectData} options={options} />
      </div>
      <div className="negative-chart">
        <h3>Distress Scores</h3>
        <Line data={negativeAffectData} options={options} />
      </div>
    </div>
  </div>
  );
};

export default MoodProfilePage;


