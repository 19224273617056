import dotenv from "dotenv";
dotenv.config();

const CONFIG = {
  BACKUP_URL: "https://psyfy.xyz",

  BASE_URL: "https://psyfy.pro",
  // BASE_URL: "https://ophiuchi.ddns.net:2224",


  SECRET_KEY: process.env.REACT_APP_SECRET_KEY,
  GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  STRIPE_KEY: process.env.REACT_APP_STRIPE_PUBLIC,
  supabaseUrl: process.env.REACT_APP_SUPABASE_URL,
  supabaseAnonKey: process.env.REACT_APP_SUPABASE_ANON_KEY,

};
export default CONFIG;

